import React, { ReactNode } from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import { ScrollTrigger } from "mui-extensions/src/ScrollTrigger";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { FadeAndSlideAnimation } from "StaticPages/Components/Animations/FadeAndSlideAnimation";
import { TransitionImage } from "StaticPages/Components/TransitionImage";
import { css } from "@emotion/react";
import { SkewAnimation } from "StaticPages/Components/Animations/SkewAnimation";
import { PageSummary } from "StaticPages/Components/Commons/PageSummary";
import { useTranslation } from "react-i18next";

export default () => {
    return <>
        <Helmet title="事業実績 | VISUALIZ INC" />
        <Layout>
            <Main />
        </Layout>
    </>;
};

const Main = () => {
    const { t } = useTranslation();
    return <>
        <main>
            <PageSummary
                title={t("事業実績")}
                subTitle="Works"
                description={<>
                    {t("クライアントとのこれまでの取り組みや")}<br />
                    {t("成果物の具体的な内容を告知。")}<br />
                    {t("人の記憶に残るための")}<br />
                    {t("イメージサービスのご紹介。")}<br />
                </>}
            />

            <Box>
                <ServiceCollectionItem
                    src="/images/works01.jpg"
                    title={t("Show Window | 2014 12month")}
                    detail={<>
                        <Box>
                            <ul>
                                <li>Resolume</li>
                                <li>Cinema4D</li>
                                <li>AE4</li>
                                <li>BenQ短焦点3000lm × 8台</li>
                                <li>MacPro × 2台</li>
                            </ul>
                        </Box>
                        <Box>
                            <Typography>{t("百貨店北側にある歩道に面したショーウィンドウに1年間映像を投影。")}</Typography>
                            <Typography>{t("百貨店文化華やかな1900年代初頭のパリをモチーフに四季を通じ管理を行った。")}</Typography>
                        </Box>
                    </>}
                />

                <ServiceCollectionItem
                    src="/images/works03.jpg"
                    title={t("Godzilla in Oyama | 2017")}
                    detail={<>
                        <Box>
                            <ul>
                                <li>Resolume</li>
                                <li>UnrealEngine</li>
                                <li>Ricoh 6700lm 2台</li>
                                <li>Arduino + Sensor</li>
                            </ul>
                        </Box>
                        <Box>
                            <Typography>{t("標高800m地点において高さ6m幅5m弱の階段部分、")}</Typography>
                            <Typography>{t("秋の長雨の中、センサー反応型の映像を約2週間投影。")}</Typography>
                            <Typography>{t("階段両脇に機材を設置し、無線で映像出力を行った。")}</Typography>
                        </Box>
                    </>}
                />

                <ServiceCollectionItem
                    src="/images/works04.jpg"
                    title={t("Shinjuku southern terrace | 2018")}
                    detail={<>
                        <Box>
                            <ul>
                                <li>Resolume</li>
                                <li>UnrealEngine</li>
                                <li>ネットワークによる遠隔管理</li>
                                <li>音響管理</li>
                                <li>Ricoh 6700lm 2台</li>
                                <li>Arduino + Sensor</li>
                                <li>スイッチハブシステム</li>
                            </ul>
                        </Box>
                        <Box>
                            <Typography>{t("操舵輪の開店に合わせ、マッピングされた約10mのリアルタイム")}</Typography>
                            <Typography>{t("レンダリングされた映像が動く仕組みを作成。")}</Typography>
                            <Typography>{t("遠隔地から機器のオンオフを管理した。")}</Typography>
                        </Box>
                    </>}
                />

                <ServiceCollectionItem
                    src="/images/works05.jpg"
                    title={t("Noborito sta | 2019")}
                    detail={<>
                        <Box>
                            <ul>
                                <li>UnrealEngine</li>
                                <li>TouchDesigner</li>
                                <li>管理システム</li>
                                <li>Realsence</li>
                                <li>赤外線Sensor</li>
                            </ul>
                        </Box>
                        <Box>
                            <Typography>{t("人を検知し浮遊するドットがキャラクターに変わる。")}</Typography>
                            <Typography>{t("ドアの前に立つとドアが開き、様々な場所へと移動できる。")}</Typography>
                            <Typography>{t("向こう3年間のスケジュール管理を作成しこの春から稼働。")}</Typography>
                            <Typography>{t("4k映像の撮影と共にフルCGでの出力、システムでの映像表現を行った。")}</Typography>
                            <Typography>
                                受賞：<br />
                                第38回 日本ディスプレイ産業賞 優秀賞<br />
                                第59回 ACC TOKYO Creative AWARD シルバー<br />
                                2019年度 日本空間デザイン賞銅賞
                            </Typography>
                        </Box>
                    </>}
                />
            </Box>

            <Box py={12} />
        </main>
    </>;
};

const ServiceCollectionItem = (
    {
        title,
        src,
        detail,
    }: {
        src: string,
        title: string,
        detail: ReactNode,
    }
) => {
    const theme = useTheme();
    return (
        <ScrollTrigger once scrollStartOffset={"10%"}>
            {state =>
                <Box
                    mt={{ xs: 4, sm: 6, md: 10 }}
                    sx={{
                        width: "100%"
                    }}
                    px={{
                        xs: 1,
                        sm: 2,
                        md: 3
                    }}
                    py={{
                        xs: 2,
                        sm: 0
                    }}
                    bgcolor={theme.palette.background.default}
                    textAlign="center"
                >
                    <Grid container
                        sx={{
                            width: "100%",
                            maxWidth: "1080px",
                            margin: "0 auto"
                        }}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <Box
                                px={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}
                                sx={{
                                    width: "100%",
                                    height: "100%"
                                }}
                            >
                                <FadeAndSlideAnimation
                                    in={state === "entered"}
                                    transform={{
                                        rotate: "0deg",
                                        translate: {
                                            x: "0px",
                                            y: "0px",
                                        },
                                        scale: 0.92,
                                    }}
                                    css={css`
                                        height: 100%;
                                    `}
                                >
                                    <img
                                        src={src}
                                        alt={title}
                                        css={css`
                                            height: 100%;
                                            width:100%;
                                            object-fit:cover;
                                        `}
                                    />
                                </FadeAndSlideAnimation>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <Box
                                p={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}
                                sx={{
                                    height: "100%"
                                }}
                                textAlign="left"
                                display="flex"
                                flexDirection="column"
                                width={"100%"}
                                maxWidth={"480px"}
                            >
                                <Box mt="auto">
                                    <SkewAnimation
                                        in={state === "entered"}
                                        bgcolor={theme.palette.background.default}
                                    >
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            sx={{
                                            }}
                                        >{title}</Typography>
                                    </SkewAnimation>
                                </Box>
                                <Box
                                    mt={{
                                        xs: 0.5,
                                        sm: 1,
                                    }}
                                    my="auto"
                                    width={"100%"}
                                >
                                    <SkewAnimation
                                        in={state === "entered"}
                                        bgcolor={theme.palette.background.default}
                                        css={css({ width: "100%" })}
                                    >
                                        <Typography
                                            variant="body2"
                                            component="div"
                                        >{detail}</Typography>
                                    </SkewAnimation>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            }
        </ScrollTrigger>
    );
};
